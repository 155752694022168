import { createBrowserRouter, createRoutesFromElements, Route, RouterProvider } from "react-router-dom"
import { ParallaxProvider } from "react-scroll-parallax";
import UnderContruction from "../pages/underContruction/index";

// const router = createBrowserRouter([
//   {
//     path: "/",
//     element: <UnderContruction />,
//   },
// ]);

const router = createBrowserRouter(
  createRoutesFromElements(
    <Route path="maintenance" element={<UnderContruction />} />
  )
);

const Routes = () => {

  return (
    <ParallaxProvider>
      <RouterProvider router={router} />
    </ParallaxProvider>
  );
};

export default Routes;
