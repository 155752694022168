import logoHeaderWhite from '../../assets/logoHeaderWhite.png';
import { HiOutlineMail } from "react-icons/hi";
import { FaWhatsapp, FaInstagram, FaLinkedinIn } from "react-icons/fa";
import './styles.css'
import Gradient1 from '../../assets/gradient1.svg'
import Gradient2 from '../../assets/gradient2.svg'

export default function UnderContruction() {


  return (
    <>
      <div class="blob-wrap x z-0 fixed">
        <div class="blob y">
          <img className='w-full h-full' src={Gradient1} alt='' />
        </div>
      </div>
      <div class="blob-wrap blob-2 x2 z-0 fixed">
        <div class="blob y">
          <img className='w-full h-full' src={Gradient2} alt='' />
        </div>
      </div>

      <div className="container-body bg-black h-screen p-10 md:py-10 md:px-20 fontMyriad">
        <div className="flex fixed w-full">
          <div className="flex w-1/3"><img src={logoHeaderWhite} className='lg:w-44 md:w-44 sm:w-36 w-36 h-8 lg:h-10 md:h-10' alt="" /></div>
        </div>
        <div className='h-5/6 flex'>
          <div className='md:w-4/6 lg:w-3/6 h-full text-white justify-center flex flex-col md:mt-10 z-10 '>
            <h1 className='text-5xl md:text-6xl lg:text-7xl font-bold'>Unleashing</h1>
            <h1 className='text-5xl md:text-6xl lg:text-7xl font-bold -mt-1 md:-mt-3'>Digital Impact...</h1>
            <p className='md:text-lg lg:text-xl font-light mt-2'>
              <span className="font-bold">Our website is currently under construction</span>,
              and we're working hard to bring you a new and improved online experience. At <span className="font-bold">Olakses</span>, we specialize in transforming  your digital presence, providing you with all-access to digital success. </p>
          </div>
          <div className='md:w-2/6 lg:w-3/6 justify-center items-center md:mt-10 hidden md:flex z-10'>
            <img src={logoHeaderWhite} className='w-8/12' alt="" />
          </div>
        </div>
        <div className='h-1/6 flex-col text-white hidden md:flex'>
          <h1 className='text-xl z-10'>Stay connected with us on:</h1>
          <div className='w-full h-full flex z-10'>
            <div className='w-3/6 items-center h-full flex flex-row'>
              <a href='mailto:bisnis@olakses.com' className='border-2 md:w-48 w-14 rounded-xl h-14 flex justify-center items-center mr-3 md:mr-5'>
                <div className='text-3xl'>
                  <HiOutlineMail />
                </div>
                <h1 className='text-xl mt-1 ml-4 md:flex hidden'>Mail</h1>
              </a>
              <a href='https://api.whatsapp.com/send/?phone=6282188879989&text&type=phone_number&app_absent=0' className='border-2 md:w-48 w-14 rounded-xl h-14 flex justify-center items-center ml-3 md:ml-5 '>
                <div className='text-3xl'>
                  <FaWhatsapp />
                </div>
                <h1 className='text-xl mt-1 ml-4 md:flex hidden'>Whatsapp</h1>
              </a>
            </div>
            <div className='w-3/6 items-center h-full flex flex-row justify-end'>
              <a href='https://www.instagram.com/olakses.id/' className='border-2 w-14 rounded-xl h-14 flex justify-center items-center mr-3'>
                <div className='text-3xl'>
                  <FaInstagram />
                </div>
              </a>
              <a href='https://www.linkedin.com/company/olakses/' className='border-2 w-14 rounded-xl h-14 flex justify-center items-center ml-3'>
                <div className='text-3xl'>
                  <FaLinkedinIn />
                </div>
              </a>
            </div>
          </div>
        </div>

        <div className='h-1/6 flex-row text-white flex md:hidden justify-center -mt-20'>
          <a href='mailto:bisnis@olakses.com' className='border-2 md:w-48 w-14 rounded-xl h-14 flex justify-center items-center mx-3 z-10'>
            <div className='text-3xl'>
              <HiOutlineMail />
            </div> </a>
          <a href='https://api.whatsapp.com/send/?phone=6282188879989&text&type=phone_number&app_absent=0' className='border-2 md:w-48 w-14 rounded-xl h-14 flex justify-center items-center mx-3 z-10'>
            <div className='text-3xl'>
              <FaWhatsapp />
            </div>
          </a>
          <a href='https://www.instagram.com/olakses.id/' className='border-2 w-14 rounded-xl h-14 flex justify-center items-center mx-3 z-10'>
            <div className='text-3xl'>
              <FaInstagram />
            </div>
          </a>
          <a href='https://www.linkedin.com/company/olakses/' className='border-2 w-14 rounded-xl h-14 flex justify-center items-center mx-3 z-10'>
            <div className='text-3xl'>
              <FaLinkedinIn />
            </div>
          </a>
        </div>
      </div >
    </>

  );
}
